const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

const seo = {
  title: "Arthur Vasseur",
  description:
    "Je suis développeur, passionné par les jeux vidéo",
  og: {
    title: "Arthur Vasseur Portfolio",
    type: "website",
    url: "https://arthurvasseur.fr/",
  },
};

//Home Page
const greeting = {
  title: "Arthur Vasseur",
  logo_name: "Arthur Vasseur",
  subTitle:
    "Je suis un développeur, passionné par les jeux vidéo",
  resumeLink:
    "ArthurVasseurCv.pdf",
  portfolio_repository: "",
  githubProfile: "https://github.com/ArthurVasseur",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/ArthurVasseur",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/arthur-vasseur-362580180/",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  }
];

const skills = {
  data: [
    {
      title: "Développement de jeux vidéo",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Développement d'un moteur de jeu en C++",
        "⚡ Développement d'un moteur graphique avec Vulkan & OpenGl",
        "⚡ Création de jeux avec Unreal Engine & Unity",
      ],
      softwareSkills: [
        {
          skillName: "Unreal Engine",
          fontAwesomeClassname: "logos:unrealengine-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Unity",
          fontAwesomeClassname: "logos:unity",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Vulkan",
          fontAwesomeClassname: "logos:vulkan",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "OpenGl",
          fontAwesomeClassname: "logos:opengl",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
            width: 100,
            height: 50,
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [

  ],
};

const degrees = {
  degrees: [
    {
      title: "BTS SIO option SLAM",
      subtitle: "Edouard Gand",
      logo_path: "edouardGand_logo.png",
      alt_name: "",
      duration: "2018 - 2020",
      descriptions: [
        "⚡ J'ai étudié les sujets de base du génie logiciel comme les structures de données et les algorithmes.",
        "⚡ J'ai également pu étudier le SQL, C# et les languages web, comme le PHP, JavaScript, HTML et CSS",
      ],
      website_link: "https://www.onisep.fr/Ressources/Univers-Formation/Formations/Post-bac/bts-services-informatiques-aux-organisations-option-b-solutions-logicielles-et-applications-metiers",
    },
    {
      title: "Programme grande école",
      subtitle: "Epitech",
      logo_path: "epitech_logo.png",
      alt_name: "Epitech",
      duration: "2020 - 2024",
      descriptions: [
        "⚡ Apprentissage du C et du C++",
        "⚡ Réalisation de divers projets comme des jeux vidéo, un client/serveur VOIP, compresseur d'image",
        "⚡ Année internationale, durant l'année scolaire 2022-2023, je suis parti à l'université de Keimyung en Corée pour approfondir mes connaissances dans le domaine du jeu vidéo"
      ],
      website_link: "https://www.epitech.eu/fr/formations/epitech-en-5-ans/",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "Machine Learning",
    //   subtitle: "- Andrew Ng",
    //   logo_path: "stanford_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
    //   alt_name: "Stanford University",
    //   color_code: "#8C151599",
    // }
  ],
};

// Experience Page
const experience = {
  title: "Expériences",
  subtitle: "Travails, Stages",
  description:
    "J'ai pu travailler dans beaucoup d'entreprises de moyenne et de grande taille, ce qui m'a permis de développer des compétences comme le travail d'équipe, mais aussi le relationel",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Travail",
      experiences: [
        {
          title: "Développeur automate",
          company: "CEFF",
          company_url: "https://www.ceff.fr/",
          logo_path: "ceff_logo.png",
          duration: "Juillet 2017",
          location: "Compiègne, France",
          description:
            "Développement d'une interface homme-machine contrôlant un automate industriel, dans le but d'automatiser l'ouverture et la fermeture des vannes",
          color: "#0879bf",
        },
        {
          title: "Développeur automate",
          company: "CEFF",
          company_url: "https://www.ceff.fr/",
          logo_path: "ceff_logo.png",
          duration: "Juillet - Août 2019",
          location: "Compiègne, France",
          description:
            "Développement d'un automate industriel et de son interface homme-machine, pour la séparation de boues contenues dans l'eau",
          color: "#9b1578",
        }
      ],
    },
    {
      title: "Stages",
      experiences: [
        {
          "title": "Développeur C++ Backend",
          "company": "Observatoire de Paris",
          "company_url": "https://www.observatoiredeparis.psl.eu//",
          "logo_path": "obs_logo.png",
          "duration": "Avril - Août 2022",
          "location": "Paris, France",
          "description": "Ajout de nouvelles routes dans une API REST développée en C++, OPALE permet de parcourir les données des éphémérides en utilisant une architecture REST https://opale.imcce.fr/webservices/",
          "color": "#0071C5"
        },
        {
          "title": "Développeur Web",
          "company": "Axians",
          "company_url": "https://www.axians.fr/",
          "logo_path": "axians_logo.png",
          "duration": "Novembre - Décembre 2017",
          "location": "Compiègne, France",
          "description": "Création d'un système de tickets pour la gestion d'incidents. Langages utilisés : PHP, JavaScript, HTML, CSS.",
          "color": "#0071C5"
        },
        {
          "title": "Technicien réseau",
          "company": "UTC (Université Technologique de Compiègne)",
          "company_url": "https://www.utc.fr/",
          "logo_path": "utc_logo.png",
          "duration": "Novembre - Décembre 2017",
          "location": "Compiègne, France",
          "description": "Administration et maintenance des infrastructures informatiques.",
          "color": "#0071C5"
        },
        {
          "title": "Développeur automate",
          "company": "CEFF",
          "company_url": "https://www.ceff.fr/",
          "logo_path": "ceff_logo.png",
          "duration": "Mai - Juin 2017",
          "location": "Compiègne, France",
          "description": "Création d’un programme pour un automate pour la gestion d'agitateurs dans un bassin d'épuration",
          "color": "#0071C5"
        },
        {
          "title": "Développeur automate",
          "company": "CEFF",
          "company_url": "https://www.ceff.fr/",
          "logo_path": "ceff_logo.png",
          "duration": "Janvier 2017",
          "location": "Compiègne, France",
          "description": "Programmation d’une interface homme machine d'un automate pour la gestion d'un chauffage urbain (CPCU)",
          "color": "#0071C5"
        },
        {
          "title": "Développeur automate",
          "company": "CEFF",
          "company_url": "https://www.ceff.fr/",
          "logo_path": "ceff_logo.png",
          "duration": "Juin - Juillet 2016",
          "location": "Compiègne, France",
          "description": "Création d’un programme d'un automate pour la gestion de pompes industrielles",
          "color": "#0071C5"
        },
        {
          "title": "Technicien réseau",
          "company": "Sanofi",
          "company_url": "https://www.sanofi.com/",
          "logo_path": "sanofi_logo.png",
          "duration": "Mars - Avril 2016",
          "location": "Compiègne, France",
          "description": "Administration et maintenance des infrastructures informatiques.",
          "color": "#ee3c26"
        }
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projets",
  description:
    "Voici une liste de mes projets scolaires et personnels, que j'ai pu réaliser durant mes études ou en dehors, dans le but de m'améliorer et de découvrir de nouvelles technologies.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Me contacter",
    profile_image_path: "",
    description:
      "Vous pouvez me contacter par mail à l'adresse suivante : arthur.vasseur@hotmail.fr, ou sur LinkedIn.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://ashutoshhathidara.wordpress.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 8320758513",
  },
};
export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
